<template>
	<div class="app-container" style="background-color: #fff;">
		<div class="filter-container">
			<div class="filter-item cate-title">
				<!-- （拖拽可调整分类顺序，保存生效） -->
				<div>商品分类</div>
				<buttonPermissions :datas="'categoryAdd'">
					<el-button type="primary" style="width:100px;" @click="cateVisable=true">添加分类</el-button>
				</buttonPermissions>
			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container">
			<el-table
				:data="cateList"
				:show-header="false"
				style="width: 100%;margin-bottom: 20px;"
				row-key="id"
				border
				:tree-props="{children: 'childCategoryList'}"
			>
				<el-table-column label="分类名称">
					<template slot-scope="scop">
						<div style="display:flex;align-items: center;">
							<div class="cate-info" v-if="scop.row.categoryLevel==3">
								<img
									v-if="scop.row.categoryLogo"
									:src="scop.row.categoryLogo+'?x-oss-process=image/resize,m_fixed,h_80,w_80'"
								/>
							</div>
							<div style="margin-left: 10px;">{{scop.row.categoryName}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="商品数量">
					<template slot-scope="scop">
						<div style="display:flex;align-items: center;">
							<div v-if="scop.row.categoryLevel==3">
								商品数量:
								<span style="color: #409eff;font-size: 16px;">{{scop.row.goodsQuantity}}</span>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="250">
					<template slot-scope="scop">
						<div style="display:flex;">
							<buttonPermissions :datas="'categoryAddChildren'">
								<el-button
									v-if="scop.row.categoryLevel==3"
									type="text"
									style="margin-left: 15px;color: transparent;"
									class="pointers"
								>添加子分类</el-button>
								<el-button
									v-else
									type="text"
									style="margin-left: 15px;"
									class="pointers"
									@click="addChild(scop.row,1)"
								>添加子分类</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'categoryEdit'">
								<el-button
									type="text"
									style="margin-left: 15px;"
									class="pointers"
									@click="addChild(scop.row,2)"
								>编辑</el-button>
							</buttonPermissions>
							<buttonPermissions :datas="'categoryDel'">
								<el-button
									type="text"
									style="margin-left: 15px;"
									class="pointers"
									@click="deleteCate(scop.row)"
								>删除</el-button>
							</buttonPermissions>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- <div>
			<el-pagination v-if="Total" style="margin:20px 0;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			:total="Total">
			</el-pagination>
		</div>-->

		<!-- 添加分类 编辑弹框 -->
		<el-dialog :visible.sync="cateVisable" width="50%" :title="cateTitle">
			<div style="display: flex;border-top: 1px solid #f5f5f5;padding-top: 20px;">
				<el-form
					:model="ruleForm"
					ref="ruleForm"
					label-width="140px"
					class="demo-ruleForm"
					:validate-on-rule-change="false"
				>
					<el-form-item
						label="一级分类名称:"
						prop="cateName"
						:rules="[{required:true,message:'一级分类名称不能为空',trigger: 'blur'}]"
					>
						<el-input
							v-model="ruleForm.cateName"
							placeholder="最多输入5个字"
							maxlength="5"
							clearable
							style="width:300px"
						></el-input>
					</el-form-item>
				</el-form>
			</div>
			<!-- 按钮区域 -->
			<div style="text-align: center;margin-top:30px;">
				<el-button style="margin-right:30px;width: 120px;" @click="cateVisable=false">关闭</el-button>
				<el-button
					type="primary"
					style="width: 120px;"
					@click="saveChange('ruleForm')"
					:loading="loading"
				>保存</el-button>
			</div>
		</el-dialog>

		<!-- 添加二级子分类 编辑弹框 -->
		<el-dialog :visible.sync="childCateVisable" width="50%" :title="childCateTitle">
			<div style="display: flex;border-top: 1px solid #f5f5f5;padding-top: 20px;">
				<el-form
					:model="ruleForm2"
					ref="ruleForm2"
					label-width="140px"
					class="demo-ruleForm"
					:validate-on-rule-change="false"
				>
					<el-form-item label="一级分类名称:" prop="cateName">
						<div class="el-dropdown-link2" style="color: #ccc;">
							<div>{{ruleForm2.cateName}}</div>
							<i class="el-icon-arrow-down el-icon--right"></i>
						</div>
					</el-form-item>

					<el-form-item
						label="二级分类名称:"
						prop="childCateName"
						:rules="[{required:true,message:'二级分类名称不能为空',trigger: 'blur'}]"
					>
						<el-input
							v-model="ruleForm2.childCateName"
							placeholder="最多输入5个字"
							maxlength="5"
							clearable
							style="width:300px"
						></el-input>
					</el-form-item>
				</el-form>
			</div>
			<!-- 按钮区域 -->
			<div style="text-align: center;margin-top:30px;">
				<el-button style="margin-right:30px;width: 120px;" @click="childCateVisable=false">关闭</el-button>
				<el-button
					type="primary"
					style="width: 120px;"
					@click="saveChange('ruleForm2')"
					:loading="loading"
				>保存</el-button>
			</div>
		</el-dialog>
		<!-- 添加三级子分类 编辑弹框 -->
		<el-dialog :visible.sync="nextChildCateVisable" width="50%" :title="nextChildCateTitle">
			<div style="display: flex;border-top: 1px solid #f5f5f5;padding-top: 20px;">
				<el-form
					:model="ruleForm3"
					ref="ruleForm3"
					label-width="140px"
					class="demo-ruleForm"
					:validate-on-rule-change="false"
				>
					<el-form-item
						label="一级分类名称:"
						prop="cateName"
						:rules="[{required:true,message:'一级分类名称不能为空',trigger: 'blur'}]"
					>
						<div class="el-dropdown-link2" style="color: #ccc;">
							<div>{{ruleForm3.cateName}}</div>
							<i class="el-icon-arrow-down el-icon--right"></i>
						</div>
					</el-form-item>
					<el-form-item
						label="二级分类名称:"
						prop="childCateName"
						:rules="[{required:true,message:'二级分类名称不能为空',trigger: 'blur'}]"
					>
						<div class="el-dropdown-link2" style="color: #ccc;">
							<div>{{ruleForm3.childCateName}}</div>
							<i class="el-icon-arrow-down el-icon--right"></i>
						</div>
					</el-form-item>
					<el-form-item
						label="三级分类名称:"
						prop="nextChildName"
						:rules="[{required:true,message:'三级分类名称不能为空',trigger: 'blur'}]"
					>
						<el-input
							v-model="ruleForm3.nextChildName"
							placeholder="最多输入5个字"
							maxlength="5"
							clearable
							style="width:300px"
						></el-input>
					</el-form-item>

					<el-form-item
						label="分类图片:"
						prop="categoryLogo"
						:rules="[{required:true,message:'请上传分类图片',trigger: 'blur'}]"
					>
						<el-upload
							class="avatar-uploader"
							:action="ruleForm3.action"
							:show-file-list="false"
							:on-success="handleAvatarSuccess"
							:before-upload="beforeAvatarUpload"
						>
							<img
								v-if="ruleForm3.categoryLogo"
								:src="ruleForm3.categoryLogo+'?x-oss-process=image/resize,m_fixed,h_80,w_80'"
								class="avatar"
							/>
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
				</el-form>
			</div>
			<!-- 按钮区域 -->
			<div style="text-align: center;margin-top:30px;">
				<el-button style="margin-right:30px;width: 120px;" @click="nextChildCateVisable=false">关闭</el-button>
				<el-button
					type="primary"
					style="width: 120px;"
					@click="saveChange('ruleForm3')"
					:loading="loading"
				>保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	categoryList,
	categorySave,
	categoryDel
} from '@/api/goods.js'
import config from '../../config/index.js'
import buttonPermissions from '@/components/buttonPermissions';
export default {
	components: {
		buttonPermissions
	},
	data () {
		return {
			cateList: [],
			currentPage: 1,
			pageSize: 20,
			Total: 0,
			cateVisable: false,
			cateTitle: '编辑分类',
			ruleForm: {
				cateName: '', //一级菜单名称
				row: {}
			},
			ruleForm2: {
				cateName: '', //一级菜单名称
				childCateName: '', //二级菜单名称
				row: {}, //一级行信息，
				row2: {}, //二级行信息
			},
			ruleForm3: {
				cateName: '', //一级菜单名称
				childCateName: '', //二级菜单名称
				nextChildName: '', //三级菜单名称
				categoryLogo: '', //上传的图片路径
				action: config.UPLOAD_IMG, //上传图片的服务器地址
				row: {}, //一级行信息
				row2: {}, //二级行信息
				row3: {}, //三级行信息
			},
			loading: false, //按钮加载状态
			childCateVisable: false,
			childCateTitle: '编辑分类',
			nextChildCateVisable: false,
			nextChildCateTitle: '编辑分类',
			menuList: [],
			childMenuList: [],
			isEdit: false,
			rowCateName: '', //选中的行对应的一级菜单名称
			rowChildCateName: '', //选中的行对应的二级菜单名称
			imgUrl: config.IMG_BASE,
		};
	},
	created () {
		this.getCategaryList();
	},
	methods: {
		//获取所有菜单
		async getCategaryList () {
			try {
				let data = {};
				let result = await categoryList(data);

				if (result.success) {
					this.cateList = result.data;
					this.menuList = [];
					this.cateList.map((item) => {
						var obj = {
							id: item.id,
							name: item.categoryName
						}
						this.menuList.push(obj);
						return item;
					})

					this.childMenuList = [];
					this.cateList[0].childCategoryList.map((item) => {
						var obj = {
							id: item.id,
							name: item.categoryName
						}
						this.childMenuList.push(obj);
						return item;
					})

				}
			} catch (err) {
				console.log(err);
			}
		},

		// 切换显示条数
		handleSizeChange (val) {
			// console.log(`每页 ${val} 条`);
			this.pageSize = val;
			this.getList();
		},

		// 翻页
		handleCurrentChange (val) {
			console.log(`当前页: ${val}`);
			this.currentPage = val;
			this.getList();
		},
		saveChange (rule) {
			var _this = this;
			this.$refs[rule].validate(async (valid) => {
				if (valid) {
					try {
						this.loading = true;
						var str = "";
						let data = {};
						if (rule == 'ruleForm') {
							if (this.isEdit) {
								data = {
									id: this.ruleForm.row.id,
									categoryLevel: 1,
									parentId: 0,
									categoryName: this.ruleForm.cateName
								};
								str = '一级类目修改成功!';
							} else {
								data = {
									categoryLevel: 1,
									parentId: 0,
									categoryName: this.ruleForm.cateName
								};
								str = '一级类目添加成功!';
							}
						} else if (rule == 'ruleForm2') {
							if (this.isEdit) {
								data = {
									id: this.ruleForm2.row2.id,
									categoryLevel: 2,
									parentId: this.ruleForm2.row.id,
									categoryName: this.ruleForm2.childCateName
								};
								str = '二级级类目修改成功!';
							} else {
								data = {
									id: 0,
									categoryLevel: 2,
									parentId: this.ruleForm2.row.id,
									categoryName: this.ruleForm2.childCateName
								};
								str = '二级级类目添加成功!';
							}

						} else if (rule == 'ruleForm3') {
							if (this.isEdit) {
								data = {
									id: this.ruleForm3.row3.id,
									categoryLevel: 3,
									parentId: this.ruleForm3.row2.id,
									categoryName: this.ruleForm3.nextChildName,
									categoryLogo: this.ruleForm3.categoryLogo
								};
								str = '三级级类目添加成功!';
							} else {
								data = {
									id: 0,
									categoryLevel: 3,
									parentId: this.ruleForm3.row2.id,
									categoryName: this.ruleForm3.nextChildName,
									categoryLogo: this.ruleForm3.categoryLogo
								};
								str = '三级级类目添加成功!';
							}
						}

						let result = await categorySave(data);

						if (result.success) {
							_this.$message({
								showClose: true,
								type: 'success',
								message: str
							});
							_this.getCategaryList();
						} else {
							_this.$message({
								showClose: true,
								type: 'error',
								message: result.alertMsg
							});
						}
						this.loading = false
						this.resetData(rule);
					} catch (error) {
						console.log(error);
						this.loading = false
					}
				}
			});
		},
		resetData (rule) {
			if (rule == 'ruleForm') {
				this.cateVisable = false;
				this.ruleForm.cateName = "";
			} else if (rule == 'ruleForm2') {
				this.childCateVisable = false;
				this.ruleForm2.childCateName = "";
			} else if (rule == 'ruleForm3') {
				this.nextChildCateVisable = false;
				this.ruleForm3.nextChildName = "";
				this.ruleForm3.categoryLogo = "";
				this.ruleForm3.childCateName = "";
			} else {
				return false;
			}
		},
		addChild (row, type) {
			//type == 1 是添加  type ==2  是编辑
			if (type == 1) {
				this.isEdit = false;
			} else {
				this.isEdit = true;
			}
			console.log(row);
			if (row.categoryLevel == 1) {

				if (this.isEdit) {
					this.ruleForm.row = row;
					this.ruleForm.cateName = row.categoryName;
					this.cateVisable = true;
				} else {
					this.ruleForm2.cateName = row.categoryName;
					this.ruleForm2.row = row;
					this.childCateVisable = true;
				}
			} else if (row.categoryLevel == 2) {
				this.cateList.map((item) => {
					if (item.childCategoryList && item.childCategoryList.length) {
						item.childCategoryList.map((item2) => {
							if (item2.parentId == row.parentId) {
								if (this.isEdit) {
									if (this.isEdit) {
										this.ruleForm2.childCateName = item.categoryName;
									} else {
										this.ruleForm2.childCateName = "";
									}
									this.ruleForm2.cateName = item.categoryName;
									this.ruleForm2.row = item;
									this.ruleForm2.row2 = row;
								} else {
									if (this.isEdit) {
										this.ruleForm3.nextChildName = item.categoryName;
									} else {
										this.ruleForm3.nextChildName = "";
									}
									this.ruleForm3.cateName = item.categoryName;
									this.ruleForm3.categoryLogo = item.categoryLogo;
									this.ruleForm3.row = item;
									this.ruleForm3.row2 = row;
								}
							}
							return item2;
						})
					}
					return item;
				})
				if (this.isEdit) {
					this.ruleForm2.childCateName = row.categoryName;
					this.childCateVisable = true;
				} else {
					this.ruleForm3.childCateName = row.categoryName;
					this.ruleForm3.categoryLogo = row.categoryLogo;
					this.nextChildCateVisable = true;
				}

			} else if (row.categoryLevel == 3) {
				this.cateList.map((item) => {
					if (item.childCategoryList && item.childCategoryList.length) {
						item.childCategoryList.map((item2) => {
							if (item2.parentId == item.id) {
								if (item2.childCategoryList && item2.childCategoryList.length) {
									item2.childCategoryList.map(item3 => {
										if (item3.id == row.id) {
											this.ruleForm3.cateName = item.categoryName;
											this.ruleForm3.row = item;
											this.ruleForm3.row2 = item2;
											this.ruleForm3.row3 = row;
											this.ruleForm3.nextChildName = row.categoryName;
											this.ruleForm3.childCateName = item2.categoryName;
											this.ruleForm3.categoryLogo = item3.categoryLogo;
										}
										return item3;
									})
								}
							}
							return item2;
						})
					}
					return item;
				})
				console.log(this.ruleForm3)
				this.nextChildCateVisable = true;
			}
		},
		deleteCate (row) {
			this.$confirm('确定要删除该条商品分类数据吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.initDelete(row);
			})
		},
		async initDelete (row) {
			let data = {
				id: row.id
			}
			let res = await categoryDel(data);
			if (res.success) {
				this.$message({
					showClose: true,
					type: "success",
					message: "删除成功",
				});
				this.getCategaryList();
			} else {
				this.$message({
					showClose: true,
					type: "error",
					message: res.alertMsg,
				});
			}
		},
		handleAvatarSuccess (res) {
			this.ruleForm3.categoryLogo = res.data[0];
		},
		beforeAvatarUpload (file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
			const isLt3M = file.size / 1024 / 1024 < 3;
			if (!isJPG) {
				this.$message.error('上传头像图片必须是 JPG 或者PNG格式!');
			}
			if (!isLt3M) {
				this.$message.error('图片大小请控制在3M以内!');
			}

			//获取地址
			// let temp = util.uploadFile(1, 0, file);
			// console.log(temp);
			// return false;
			return isJPG && isLt3M;
		}
	},
};
</script>

<style lang="less">
.bottom-page {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.cate-info > img {
	width: 80px;
	height: 80px;
}

.el-table .cell {
	display: flex;
}

.pointers {
	cursor: pointer;
}

.cate-title {
	display: flex !important;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 10px;
}

.el-dropdown-link {
	// width: 300px;
	display: flex;
	justify-content: space-between;
	border-radius: 5px;
	align-items: center;
	// padding: 0 12px;
	// border: 1px solid #d4d5d6;
}
.el-dropdown-link2 {
	width: 300px;
	display: flex;
	justify-content: space-between;
	border-radius: 5px;
	align-items: center;
	padding: 0 12px;
	border: 1px solid #d4d5d6;
}

.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}

.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 100px;
	height: 100px;
	line-height: 100px;
	text-align: center;
}

.avatar {
	width: 100px;
	height: 100px;
	display: block;
}
</style>
